@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.95)
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.font-outfit {
  font-family: "Outfit", sans-serif;
}
